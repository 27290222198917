import {fetchUtils, withLifecycleCallbacks} from 'react-admin';
import type {DataProvider} from 'react-admin';
import {appKey} from '../App';
import restDataProvider from './restDataProvider';

const fetchJson = (url: string, opt: any) => {
    const options = opt === undefined ? {} : opt;

    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }

    try {
        const auth = localStorage.getItem('auth') || '{}';
        const authData = JSON.parse(auth);

        if (authData?.token) {
            options.headers.set('X-ACCESS-TOKEN', authData.token);
        }
        options.headers.set('locale', localStorage.getItem(`RaStore${appKey}.locale`) || 'en');
    } catch (e) {
        console.log(e);
    }

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = withLifecycleCallbacks(
    restDataProvider(
        import.meta.env.VITE_API_RESOURCE_URL || '',
        fetchJson
    ),

    [
        {
            resource: "product-manuals",
            beforeSave: async (params: any, dataProvider: DataProvider) => {

                const file = params?.manual;
                const fileType = params?.manual.rawFile.type;

                const response = await dataProvider.create('product-manuals/pre-signed-url', {
                    data: {
                        fileName: file.title,
                        fileType: fileType,
                    }
                });

                const fileData = response.data;

                await fetchUtils.fetchJson(fileData.url, {
                    method: "PUT",
                    body: file.rawFile,
                    headers: new Headers({Accept: fileType, "Content-Type": fileType})
                });

                return {
                    ...params,
                    fileKey: fileData.fileKey,
                    url: fileData.fileKey
                };
            },
        },
        {
            resource: "product-media",
            beforeCreate: async (params: any, dataProvider: DataProvider) => {
                const file = params.data?.media;
                const rawFile = params.data?.media.rawFile;

                const response = await dataProvider.create('product-media/pre-signed-url', {
                    data: {
                        fileName: file.title,
                        fileType: rawFile.type,
                        product: params.data.product,
                    }
                });

                const fileData = response.data;

                await fetchUtils.fetchJson(fileData.url, {
                    method: "PUT",
                    body: file.rawFile,
                    headers: new Headers({Accept: rawFile.type, "Content-Type": rawFile.type})
                });

                return {
                    data: {
                        product: params.data.product,
                        title: fileData.fileKey,
                        contentType: rawFile.type
                    },
                    meta: params.meta
                };
            },
        },
        {
            resource: "products",
            beforeUpdate: async (params: any, dataProvider: DataProvider) => {

                if (params.data?.colorImage || params.data?.sizeImage) {
                    const file = params.data?.colorImage ?? params.data?.sizeImage;
                    const field = params.data?.colorImage ? 'colorImage' : 'sizeImage';
                    const rawFile = file.rawFile;

                    const response = await dataProvider.create('product-media/pre-signed-url', {
                        data: {
                            fileName: file.title,
                            fileType: rawFile.type,
                            product: `/products/${params.previousData.id}`,
                        }
                    });

                    const fileData = response.data;

                    await fetchUtils.fetchJson(fileData.url, {
                        method: "PUT",
                        body: file.rawFile,
                        headers: new Headers({Accept: rawFile.type, "Content-Type": rawFile.type})
                    });

                    const urlObj = new URL(fileData.url);

                    return {
                        ...params,
                        data: {
                            [field]: {
                                key: fileData.fileKey,
                                url: `${urlObj.origin}${urlObj.pathname}`
                            }
                        },
                    };
                } else {
                    return params;
                }
            }
        }
    ]
);

export default dataProvider;
