import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardActions,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Typography
} from '@mui/material';
import {
    Form,
    required,
    TextInput,
    useTranslate,
    useLogin,
    useNotify, LocalesMenuButton, useCreate
} from 'react-admin';
import backgroundImage from './images/background.jpg';
import LoginLogo from "./LoginLogo";

export const MFA_REQUIRED = 'MfaRequiredError';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const [create] = useCreate();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                {
                    type: 'error',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                    ? error.message
                                    : undefined,
                    },
                }
            );
        });
    };

    const handleSubmitResetPassword = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const email = formJson.email;

        try {
            await create('auth/password/forgotten', {
                data: {email}
            });

            notify('resources.user.reset_password_success', {type: 'success'});
            handleClose();
        } catch (error: any) {
            const e = error?.body?.error?.errors;

            if (e?.email) {
                notify(e?.email, {
                    type: 'error',
                    autoHideDuration: 5000,
                });
            }

            if (error?.body?.error?.message) {
                notify(error.body.error.message, {
                    type: 'error',
                    autoHideDuration: 5000,
                });
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Box
                    sx={{
                        marginTop: '6em',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <LocalesMenuButton/>
                </Box>
                <Card sx={{minWidth: 300}}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <LoginLogo/>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        Admin Panel
                    </Box>
                    <Box sx={{padding: '0 1em 1em 1em'}}>
                        <Box sx={{marginTop: '1em'}}>
                            <TextInput
                                autoFocus
                                name="email"
                                source="email"
                                label={translate('ra.auth.username')}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                        <Box sx={{marginTop: '-.1em'}}>
                            <TextInput
                                name="password"
                                source="password"
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <CardActions sx={{padding: '0 1em 1em 1em'}}>
                        <Box sx={{width: '100%'}}>
                            <Box>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress size={25} thickness={2}/>
                                    )}
                                    {translate('ra.auth.sign_in')}
                                </Button>
                            </Box>
                            <Box sx={{marginTop: '1em'}}>
                                <Button variant="text" size="small" fullWidth onClick={handleClickOpen}>
                                    {translate('resources.user.reset_password')}
                                </Button>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        component: 'form',
                                        onSubmit: handleSubmitResetPassword
                                    }}
                                >
                                    <DialogTitle>
                                        {translate('resources.user.reset_password_instruction')}
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography variant="body1" gutterBottom>
                                            {translate('resources.user.reset_password_instruction_step1')}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {translate('resources.user.reset_password_instruction_step2')}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {translate('resources.user.reset_password_instruction_step3')}
                                        </Typography>
                                        <Typography variant="subtitle2" gutterBottom>
                                            {translate('resources.user.reset_password_instruction_footer')}
                                        </Typography>
                                        <TextInput
                                            autoFocus
                                            name="email"
                                            source="email"
                                            label={translate('resources.user.fields.email')}
                                            disabled={loading}
                                            validate={required()}
                                            fullWidth
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button type="submit">
                                            {translate('resources.user.reset_password_button')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
    email?: string;
    password?: string;
}
