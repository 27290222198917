import * as React from 'react';
import {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardActions,
    CircularProgress,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from '@mui/material';
import {
    required,
    TextInput,
    useTranslate,
    useNotify,
    SimpleForm,
    PasswordInput,
    minLength,
    useCreate,
    email,
    useRedirect,
    LocalesMenuButton
} from 'react-admin';
import backgroundImage from './images/background.jpg';
import LoginLogo from "./LoginLogo";
import CircleIcon from '@mui/icons-material/Circle';

interface ResetFormFields {
    uid?: string;
    email?: string;
    password?: string;
    passwordRepeat?: string;
}

const validation = async (values: ResetFormFields) => {
    const errors: ResetFormFields = {};

    if (values?.password) {
        if (
            !values.passwordRepeat ||
            values.passwordRepeat !== values.password
        ) {
            errors.passwordRepeat = 'resources.user.validation.password_dont_match';
        }
    }

    const validEmail = email()(values.email);

    if (!values.email || values.email.trim() === '') {
        errors.email = 'ra.validation.required';
    } else if (typeof validEmail !== "string" && validEmail?.message) {
        errors.email = validEmail.message;
    }

    return errors;
};

const ResetPassword = (props: any) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();

    const notify = useNotify();
    const location = useLocation();
    const [create] = useCreate();
    const redirect = useRedirect();

    const handleSubmit = useCallback(
        async (values: ResetFormFields) => {
            try {
                setLoading(true);
                await create(
                    `auth/password/reset`,
                    {data: values}
                );

                notify('resources.user.updated_successfully', {
                    type: 'success',
                    autoHideDuration: 5000,
                });
                redirect(`/login`);
            } catch (error: any) {
                const e = error?.body?.error;

                if (e?.errors) {
                    return error.body.error.errors;
                }

                if (error?.body?.error?.message) {
                    notify(error.body.error.message, {
                        type: 'error',
                        autoHideDuration: 5000,
                    });
                }
            }
        },
        [create, notify, redirect]
    );

    return (
        <SimpleForm
            {...props}
            onSubmit={handleSubmit}
            validate={validation}
            record={{uid: location.search.split('=')[1]}}
            sx={{padding: 0}}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        margin: '1em',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <LocalesMenuButton/>
                </Box>
                <Card sx={{minWidth: 300}}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <LoginLogo/>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        {translate('resources.user.reset_password')}
                    </Box>
                    <Box sx={{padding: '0 1em 0em 1em'}}>
                        <Box sx={{marginTop: '1em'}}>
                            <TextInput
                                name="uid"
                                source="uid"
                                hidden={true}
                                type={'hidden'}
                                sx={{display: 'none'}}
                            />
                            <TextInput
                                autoFocus
                                name="email"
                                source="email"
                                label={'E-mail'}
                                validate={[required(), email()]}
                                fullWidth
                            />
                        </Box>
                        <Box display={{xs: 'block', sm: 'flex'}} sx={{width: '100%'}}>
                            <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                <PasswordInput
                                    label={`resources.user.fields.newPassword`}
                                    name="password"
                                    source="password"
                                    validate={[minLength(6)]}
                                    fullWidth
                                />
                            </Box>
                            <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                <PasswordInput
                                    label={`resources.user.fields.repeatNewPassword`}
                                    name="passwordRepeat"
                                    source="passwordRepeat"
                                    validate={[minLength(6)]}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box px={1} mb={2} ml={2}>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ color: '#212121', fontSize: '0.9rem', mb: 0.5 }}
                        >
                            {translate('resources.user.password_strength_requirements_title')}
                        </Typography>
                        <List dense sx={{ py: 0 }}>
                            {[
                                translate('resources.user.password_strength_requirements_1'),
                                translate('resources.user.password_strength_requirements_2'),
                                translate('resources.user.password_strength_requirements_3'),
                                translate('resources.user.password_strength_requirements_4'),
                                translate('resources.user.password_strength_requirements_5')
                            ].map((requirement, index) => (
                                <ListItem key={index} sx={{ py: 0, my: 0 }}>
                                    <ListItemIcon sx={{ minWidth: '18px', color: '#212121' }}>
                                        <CircleIcon sx={{ fontSize: '0.6rem' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={requirement}
                                        primaryTypographyProps={{ style: { color: '#212121', fontSize: '0.85rem', lineHeight: '1.2' } }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <CardActions sx={{padding: '0 1em 1em 1em'}}>
                        <Box sx={{width: '100%'}}>
                            <Box>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress size={25} thickness={2}/>
                                    )}
                                    {translate('resources.user.final_reset_password_button')}
                                </Button>
                            </Box>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
        </SimpleForm>
    );
};

ResetPassword.propTypes = {
    authProvider: PropTypes.func,
};

export default ResetPassword;
