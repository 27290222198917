import {
    AutocompleteArrayInput,
    maxLength,
    maxValue, minLength,
    minValue, number, NumberInput, required,
    SelectInput,
    TextInput,
    TranslatableInputs,
    useTranslate
} from "react-admin";
import {Alert, InputAdornment} from "@mui/material";
import {MarkdownInput} from "@react-admin/ra-markdown";
import * as React from "react";
import {Attribute} from "../../types";

const AttributeFieldValue = (props: { attribute?: Attribute }) => {

    const {attribute} = props;
    const t = useTranslate();

    let type: string = '';
    let validations: any[] = [required()];

    if (attribute !== undefined) {
        type = attribute.valueType;

        if (attribute.valueValidations) {
            const rules = attribute.valueValidations.split(',');

            for (let i = 0; i < rules.length; i++) {
                const rule = rules[i].split('|');
                const ruleName = rule[0];
                const ruleValue = rule[1];

                switch (ruleName) {
                    case 'MIN':
                        validations.push(minValue(parseInt(ruleValue, 10)));
                        break;
                    case 'MAX':
                        validations.push(maxValue(parseInt(ruleValue, 10)));
                        break;
                    case 'MAX_LENGTH':
                        validations.push(maxLength(parseInt(ruleValue, 10)));
                        break;
                    case 'MIN_LENGTH':
                        validations.push(minLength(parseInt(ruleValue, 10)));
                        break;
                }
            }
        }
    }

    switch (type) {
        case 'INTEGER':
            validations.push(number());
            return (
                <NumberInput
                    name="value"
                    source="value"
                    label="resources.products.show.attribute_value"
                    hiddenLabel={false}
                    step={1}
                    validate={validations}
                    fullWidth
                />
            );
        case 'MEASUREMENT':
            validations.push(number());
            return (
                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                    <NumberInput
                        source="value_translatable"
                        label=" "
                        hiddenLabel={true}
                        step={0.01}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                >
                                    {attribute?.valueUnit}
                                </InputAdornment>
                            ),
                        }}
                        validate={validations}
                    />
                </TranslatableInputs>
            );
        case 'LONG_TEXT':
            return (
                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                    <MarkdownInput
                        source="value_translatable"
                        label="resources.products.show.attribute_value"
                        toolbarItems={[
                            ['heading', 'bold', 'italic', 'strike'],
                            ['hr', 'quote'],
                            ['ul', 'ol', 'indent', 'outdent'],
                            ['table', 'link'],
                            ['code', 'codeblock'],
                        ]}
                        validate={validations}
                    />
                </TranslatableInputs>
            );
        case 'LIST_MULTIPLE_VALUES':
            return (
                <AutocompleteArrayInput
                    source="value"
                    choices={attribute?.attributeValues}
                    optionValue="id"
                    optionText="value"
                    label="resources.products.show.attribute_value"
                    fullWidth
                    validate={validations}
                    format={collection => {
                        if (typeof collection === 'string') {
                            return collection.split(',');
                        }

                        return collection;
                    }}
                />
            );
        case 'LIST':
            return (
                <SelectInput
                    source="value"
                    name="value"
                    optionValue="id"
                    optionText="value"
                    choices={attribute?.attributeValues}
                    fullWidth
                    validate={validations}
                />
            );
        case 'BOOLEAN':
            return (
                <SelectInput
                    source="value"
                    name="value"
                    optionValue="id"
                    optionText="value"
                    choices={[
                        {id: "1", value: "ra.boolean.true"},
                        {id: "0", value: "ra.boolean.false"}
                    ]}
                    fullWidth
                    validate={validations}
                />
            );
        case 'TEXT':
            return (
                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                    <TextInput
                        source="value_translatable"
                        label="resources.products.show.attribute_value"
                        validate={validations}
                    />
                </TranslatableInputs>
            );
        default:
            return (
                <Alert severity="warning" sx={{width: "calc(100% - 32px)"}}>
                    {t('resources.products.show.select_attribute_first')}
                </Alert>
            );
    }

};

export default AttributeFieldValue;
