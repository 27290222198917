import {
    AutocompleteInput,
    Identifier,
    required,
    SimpleForm,
    useDataProvider, useGetList, useListContext,
    useNotify, useRefresh, useTranslate, useUnselectAll
} from "react-admin";
import {Alert, Typography} from "@mui/material";
import {BulkUpdateFormButton} from "@react-admin/ra-form-layout";
import * as React from "react";
import {Attribute} from "../../types";
import {useState, useEffect} from "react";
import AttributeFieldValue from "./AttributeFieldValue";
import EditNoteIcon from '@mui/icons-material/EditNote';

const AttributeBulkUpdateButton = () => {
    const t = useTranslate();
    const dp = useDataProvider();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('products');
    const notify = useNotify();
    const { selectedIds } = useListContext();

    const [attributeId, setAttributeId] = useState<Identifier | undefined>(undefined);
    const [attribute, setAttribute] = useState<Attribute | undefined>(undefined);
    const attributes = useGetList<Attribute>('attributes', {pagination: {page: 1, perPage: 9999}}, {staleTime: 10000});

    useEffect(() => {
        if (attributeId !== undefined) {
            dp.getOne<Attribute>(`attributes`, {
                id: attributeId as Identifier,
            })
                .then(({data}) => {
                    setAttribute(data);
                })
                .catch(error => {
                    console.log(error.data);
                });
        }
    }, [attributeId]);

    if (attributes.isLoading)
        return null;

    const attributesChoices: Attribute[] = attributes.data as Attribute[];
    const choices = attributesChoices.map(attr => ({
        id: attr.id,
        '@id': attr['@id'],
        name: attr.defaultMarketplace ? `${attr.name} (${attr.defaultMarketplace.name})` : attr.name
    }));

    const attributeUpdate = (attributeIri: any) => {
        const parts = attributeIri.split('/');
        const id = parseInt(parts[parts.length - 1], 10);
        setAttributeId(id);
    };

    return (
        <BulkUpdateFormButton
            icon={<EditNoteIcon/>}
            label={`resources.products.list.update_attributes`} mutationMode={'pessimistic'}
            DialogProps={
                {
                    sx: {
                        '& .MuiDialog-paper': {minWidth: {md: '30%'}},
                    }
                }
            }
            mutationOptions={{
                onError: (error: any) => {
                    const message = error?.body?.error?.message;

                    if (message) {
                        notify(message, {
                            type: 'error',
                            autoHideDuration: 5000,
                        });
                    }
                    //
                    let close = document.getElementsByClassName('MuiModal-backdrop');
                    // @ts-ignore
                    close[0].click();
                },
                onSuccess: () => {
                    notify('ra.notification.updated', {
                        type: 'success',
                        messageArgs: { smart_count: selectedIds.length },
                        undoable: false
                    });

                    refresh();
                    setAttribute(undefined);
                    setAttributeId(undefined);
                    unselectAll();
                }
            }}
        >
            <Typography variant="h6" component="h2" sx={{marginTop: '-22px', paddingLeft: '16px'}}>
                {t(`resources.products.list.update_attribute_dialog_title`)}
            </Typography>
            <SimpleForm>
                <Alert severity="error" sx={{width: "calc(100% - 32px)"}}>
                    <b>{t(`resources.products.list.warning_label`)}</b>{t(`resources.products.list.warning_message`)}
                </Alert>
                <AutocompleteInput
                    optionText="name"
                    optionValue="@id"
                    fullWidth={true}
                    choices={choices}
                    name="attribute"
                    validate={[required()]}
                    label={`resources.products.show.attribute_name`}
                    onChange={(field) => {
                        attributeUpdate(field);
                    }}
                />
                <AttributeFieldValue attribute={attribute}/>
            </SimpleForm>
        </BulkUpdateFormButton>
    );
};

export default AttributeBulkUpdateButton;
